<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.75 13.125V12.375C11.75 11.3249 11.75 10.7999 11.5456 10.3988C11.3659 10.046 11.079 9.75913 10.7262 9.57936C10.3251 9.375 9.8001 9.375 8.75 9.375H6C4.9499 9.375 4.42485 9.375 4.02377 9.57936C3.67096 9.75913 3.38413 10.046 3.20436 10.3988C3 10.7999 3 11.3249 3 12.375V13.125M9.5625 4.6875C9.5625 5.89562 8.58312 6.875 7.375 6.875C6.16688 6.875 5.1875 5.89562 5.1875 4.6875C5.1875 3.47938 6.16688 2.5 7.375 2.5C8.58312 2.5 9.5625 3.47938 9.5625 4.6875Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: "IconMember"
}
</script>
